











































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import Editor from '@tinymce/tinymce-vue';

import { isEqual, cloneDeep, get } from 'lodash';

import TableMenu from './TableMenu.vue';
import InsertItemDialog from './InsertItemDialog.vue';

const FIELD_MAP = {
	styles: 'style',
	loops: 'loop',
	tags: 'tag',
	tempos: 'tempo',
	midi: 'midi',
	categories: 'category',
	variants: 'variant',
	packs: 'pack',
	video: 'videos',
};

interface Upload {
	field: any;
	file: any;
}

@Component({
	components: {
		TableMenu,
		InsertItemDialog,
		Editor,
	},
})
export default class EditForm extends Vue {
	@Prop(Object) readonly item: any | {};

	@Prop(String) readonly model!: string | '';

	@Prop(String) readonly search!: string | '';

	@Prop(Object) readonly schema: any | {};

	@Prop(Object) readonly relationships!: {};

	@Prop(Function) readonly saveItemMethod!: () => {};

	uploads: Upload[] = [];

	addFieldListItemModel = false;

	isDirty = false;

	originalItem = cloneDeep(this.item);

	selected = {};

	editorApiKey = '2vxkik7j7pmesdi9wik2xxgbnj733kzrx9uolu23z6y6ovid';

	editorConfig = {
		height: 500,
		menubar: false,
		plugins: [
			'advlist autolink lists link image charmap print preview anchor',
			'searchreplace visualblocks code fullscreen',
			'insertdatetime media table paste code help wordcount',
		],
		toolbar:
			'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
	};

	/**
	 * Returns true if current schema contains an upload
	 */
	hasUploads() {
		return Object.keys(this.schema).some(k => k.includes('Url'));
	}

	// Methods

	createFieldListItem(item, type) {
		this.$router.push(
			{ name: 'edit-item', params: { id: 'new', type: FIELD_MAP[type] } },
			() => {
				this.$forceUpdate();
			}
		);
	}

	editFieldListItem(payload: any) {
		const { item, type } = payload;

		// opens edit form for specific item
		this.$router.push({
			name: 'edit-item',
			params: { id: item._id, type: FIELD_MAP[type] },
		});
	}

	deleteFieldListItem(field, item) {
		// delete from parent obj, not from DB!
		/* eslint-disable */
		if (
			window.confirm(
				`Are you sure you want to remove ${item.name} from this object`
			)
		) {
			this.removeItem(field, item);
			this.saveItem();
		}
	}

	duplicateFieldListItem(field, item, refresh = true) {
		// duplicate an item, then add to parent object
		/* eslint-disable */
		if (window.confirm(`Are you sure you want to duplicate ${item.name}?`)) {
			return this.$store
				.dispatch('DUPLICATE_MANAGE_ITEM', { model: FIELD_MAP[field], item })
				.then(dupe => {
					this.addItem(field, dupe);
					this.saveItem();
				});
		}

		return;
	}

	handleListAction(field, list, action) {
		const promises: any = [];

		if (!action) {
			return;
		}

		if (list.length) {
			list.forEach(item => {
				promises.push(
					this.$store.dispatch(action, { model: FIELD_MAP[field], item })
				);
			});

			Promise.all(promises)
				.then(items => {
					items.forEach(it => {
						this.addItem(field, it);
					});
				})
				.finally(() => {
					this.saveItem();
				});
		}
	}

	async duplicateList(field) {
		const list = this.selected[field] || [];
		const promises: any = [];

		/* eslint-disable */
		if (
			window.confirm(
				`Are you sure you want to duplicate ${list.length} items and add them to this object?`
			)
		) {
			this.handleListAction(field, list, 'DUPLICATE_MANAGE_ITEM');
		}
	}

	async deleteList(field) {
		const list = this.selected[field] || [];
		const promises: any = [];

		/* eslint-disable */
		if (
			list.length &&
			window.confirm(
				`Are you sure you want to remove these ${list.length} items? This does not delete the item from the database, just removes from this object`
			)
		) {
			list.forEach(item => {
				this.removeItem(field, item);
			});

			this.saveItem();
		}
	}

	handleInsertItem(field: any, item: any) {
		// add the item
		this.addItem(field, item);
		this.saveItem();
	}

	get(obj: any, a: string, b: string) {
		return get(obj, a, b);
	}

	isUpload(field: any) {
		return field.indexOf('Url') > -1;
	}

	is(is, field) {
		return this.schema[field] === is;
	}

	isImage(url: string) {
		if (!url) {
			return false;
		}

		let isJPG = false;
		let isJPEG = false;
		let isPNG = false;

		if (url.startsWith('data')) {
			const type3 = url.slice(11, 14);
			const type4 = url.slice(11, 15);
			isJPG = type3 === 'jpg';
			isJPEG = type4 === 'jpeg';
			isPNG = type3 === 'png';
		} else {
			isJPG = url.endsWith('jpg');
			isJPEG = url.endsWith('jpeg');
			isPNG = url.endsWith('png');
		}

		return isJPG || isJPEG || isPNG;
	}

	isVideo(url: string) {
		if (!url) {
			return false;
		}

		let isMP4 = false;

		if (url.startsWith('data')) {
			const type3 = url.slice(11, 14);
			isMP4 = type3 === 'mp4';
		} else {
			isMP4 = url.endsWith('mp4');
		}

		return isMP4;
	}

	isAudio(url: string) {
		if (!url) {
			return false;
		}

		let isMP3 = false;

		if (url.startsWith('data')) {
			const type3 = url.slice(11, 14);
			isMP3 = type3 === 'mp3';
		} else {
			isMP3 = url.endsWith('mp3');
		}

		return isMP3;
	}

	getDateString(value) {
		if (value) {
			return new Date(value).toISOString().substr(0, 10);
		}
		return undefined;
	}

	isRef(field: any) {
		return (
			typeof this.schema[field] === 'object' &&
			Object.keys(this.schema[field]).includes('ref')
		);
	}

	isRefArray(field) {
		return Array.isArray(this.schema[field]);
	}

	getItemValue(item: any) {
		return { _id: item._id };
	}

	checkIsDirty(newItem) {
		this.isDirty = !isEqual(this.originalItem, newItem);
	}

	handleDateChange(field) {
		const dateInputField: any = this.$refs[`dateInput-${field}`];
		if (dateInputField && dateInputField.length) {
			this.item[field] = new Date(dateInputField[0].inputDate).getTime();
			this.checkIsDirty(this.item);
		}
	}

	getRelationship(field) {
		let refType = null;

		if (
			typeof this.schema[field] === 'object' &&
			Object.keys(this.schema[field]).includes('ref')
		) {
			refType = this.schema[field].ref;
		}

		if (
			Array.isArray(this.schema[field]) &&
			Object.keys(this.schema[field][0]).includes('ref')
		) {
			refType = this.schema[field][0].ref;
		}

		if (refType) {
			return this.relationships[refType];
		}

		return [];
	}

	addItem(field: any, item: any) {
		if (!this.item[field]) {
			this.item[field] = [];
		}
		this.item[field].push(item);
	}

	removeItem(field: any, item: any) {
		this.item[field] = this.item[field].filter(i => i._id !== item._id);
	}

	pendingUpload(field: any) {
		return this.uploads.find((u: any) => u.field === field);
	}

	addUpload(upload: any) {
		if (this.pendingUpload(upload.field)) {
			this.removeUpload(upload.field);
		}
		this.uploads.push(upload);
	}

	async saveItem() {
		// upload images
		const item = await this.saveItemMethod();
		this.uploadImages(item);
	}

	removeUpload(field: any) {
		this.uploads = this.uploads.filter(u => u.field !== field);
	}

	processLocalUpload(e: any, field: any) {
		const fileReader = new FileReader();
		const file = e.target.files[0];

		let dataUrl;

		fileReader.addEventListener(
			'load',
			() => {
				dataUrl = fileReader.result;
				const upload = {
					field,
					file,
					src: dataUrl,
				};

				this.addUpload(upload);
			},
			false
		);

		if (file) {
			fileReader.readAsDataURL(file);
		}
	}

	handleCreated(payload) {
		const { field, item } = payload;
		this.addItem(field, item);
		// this.$emit('refreshRelationships');
	}

	uploadFile(refName) {
		const ref: any = this.$refs[refName];
		if (ref && ref.length) {
			ref[0].click();
		}
	}

	async uploadImages(newItem: any) {
		return new Promise((resolve, reject) => {
			const uploadPromises: Promise<any>[] = [];
			for (const upload of this.uploads) {
				const type = upload.field.replace('Url', '');
				const payload = {
					id: this.item._id || newItem._id,
					model: this.model,
					type,
					filename: `${type}-${Date.now()}.${upload.file.type.split('/')[1]}`,
					file: upload.file,
				};
				uploadPromises.push(this.$store.dispatch('UPLOAD_EDIT_ITEM', payload));
			}

			return Promise.all(uploadPromises)
				.then(res => {
					console.log(res);
					resolve(newItem);
				})
				.catch(err => {
					console.log(err);
					reject();
				});
		});
	}
}
